html, body{
    font-family:'Roboto', sans-serif;
    background-color:#FDFDFD;
}
.App{
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
}
.header-content{
    height:64px;
    max-height:64px;
    flex-grow:1;
    width:100%;
    color:#fff;
    background-color:#3f51b5;
    box-shadow:0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
}
.header{
    height:64px;
    display:flex;
    z-index:1100;
    margin:auto;
    padding-left:24px;
    padding-right:24px;
}

.header .header-search{
    position:relative;
    background-color:rgba(255, 255, 255, 0.15);
    border-radius:4px;
    margin:auto;
    min-width:50%;
}
.header .header-search .header-search-icon{
    width:56px;
    height:100%;
    display:flex;
    position:absolute;
    align-items:center;
    pointer-events:none;
    justify-content:center;

}
.header .header-search .header-search-input input{
    padding:8px 8px 8px 56px;
    color:white;
}

.header-titles-container{
    position:absolute;
    display:flex;
    top:20px;
}

.header-titles-container .header-title-icon{
    margin-right:4px;
}

div.footer{
    background-color:#3f51b5;
    padding:60px 0;
    margin-top:auto;
}

div.footer-content{
    color:white;
    font-size:12px;
    display:flex;
    align-items:center;
}

div.footer-content span{
    padding-bottom:4px;
}
div.footer-content .mail-icon{
    color:white;
}

div.sessions_container{
    padding:20px 0;
    margin:auto;
}
div.sessions_container.playing{
    /*padding-top:80px;*/
}

.session_card{
    position:relative;
    padding:6px;
    margin-bottom:20px;
}
div.session_card.playing{
    background-color:#eaf5f5;
    position:sticky;
    top:20px;
    bottom:20px;
    z-index:1000000000000000;
}

div.player-content{
    display:flex;
    align-items:flex-end;
}

div.player-content div.player{
    width:100%;
}

div.player-header{
    display:flex;
    align-items:center;
}
div.player-header h3, div.player-header h4{
    padding:0;
    margin:0;
}
div.player-header h3{
    font-weight:normal;
    font-size:17px;
}
div.player-header h4{
    font-weight:normal;
    font-size:15px;
}
div.player-header .MuiSvgIcon-fontSizeSmall{
    font-size:1rem !important;
}
div.player-header div.card-info{
    display:flex;
    align-items:center;
    font-size:14px;
}
div.player-header div.card-info > *{
    margin-right:10px;
}
div.player-header div.card_header{
    padding-top:2px;
}
div.player-header div.btn_play{
    padding:0 10px;
    cursor:pointer;
    color:#3f51b5;
}
div.player-header div.btn_play .MuiSvgIcon-root{
    height:2em;
    width:2em;
}
div.player-header div.punctuation-content{
    display:flex;
    align-content:flex-end;
}
div.player-header div.top-right{
    padding-right:2%;
    margin-left:auto;
    margin-top:auto;
}
div.player-bottom{
    display:flex;
    width:98%;
    margin:-4px auto 0 auto;
}
div.player-bottom div.current-time{
    text-align:center;
}
div.player-bottom div.audio-duration{
    margin-left:auto;
}
div.player-bottom div.current-time{
    margin-right:auto;
}
div.player-bottom div.audio-duration, div.player-bottom div.current-time{
    font-size:12px;
}
div.player-bottom div.buttons{
    margin-bottom:5px;
    display:flex;
    align-items:center;
}
div.player-bottom div.buttons > div .MuiSvgIcon-root{
    height:0.6em !important;
    width:0.6em !important;
    margin-right:5px;
    padding-bottom:2px;
    margin-top:auto;
}
div.player-bottom div.buttons > div > span{
    margin-top:auto;
}
div.player-bottom div.buttons > div{
    border-radius:3px;
    font-size:11px;
    padding:2px 9px 2px 8px;
    height:22px;
    line-height:16px;
    background-color: #fff;
    color:#333;
    display:flex;
    align-items:center;
}
div.player-bottom div.buttons > div:active{
    transform: translateY(1px);
}
div.player-bottom div.buttons div.btn_download, div.player-bottom div.buttons div.btn_to_cache{
    border:1px solid #e5e5e5;
    cursor:pointer;
}
div.player-bottom div.buttons div.btn_download:hover, div.player-bottom div.buttons div.btn_to_cache:hover{
    border-color:#ccc;
}
div.player-bottom div.buttons div.btn_cached{
    background-color: #3f51b5;
    color: white;
    cursor: context-menu;
}
div.player-bottom div.buttons div.btn_delete_cache{
    color:white;
    background-color:darkred;
    cursor:pointer;
}
div.player-bottom div.buttons .mini-loader{
    width:11px !important;
    height:11px !important;
    margin-right:5px;
    margin-top:2px;
}
div.player-bottom div.buttons > div:hover{

}
div.player-bottom div.buttons > div:not(:last-child){
    margin-right:5px;
}

.progress-bar-container{
    margin:10px auto;
    width:98%;
    user-select:none;
    position:relative;
    cursor:pointer;
}
.progress-bar-container .progress-bar{
    width:100%;
    height:8px;
    background-color:lightgrey;
    cursor:pointer;

}
.progress-bar-container .progress-bar .handler{
    position:absolute;
    height:8px;
    width:1px;
    background-color:grey;
}
.progress-bar-container .progress-bar .buffers{
    position:absolute;
    bottom:8px;
    height:4px;
    width:100%;
}
.progress-bar-container .progress-bar .buffers > div{
    position:absolute;
    background-color:#fffede;
    height:4px;
    top:0;
    left:20px;
    width:300px;
}
.progress-bar-container .progress-bar-waveform{
    width:100%;
}
.progress-bar-container .progress-bar-waveform.hidden{
    display:none;
}
.progress-bar-container .progress-bar-waveform .waveform{
    padding:6px 0;
}
.progress-bar-container .progress-bar-waveform .buffers{
    position:absolute;
    top:0;
    height:100%;
    width:100%;
}
.progress-bar-container .progress-bar-waveform .buffers > div{
    position:absolute;
    background-color:#fffede;
    height:100%;
    top:0;
    left:20px;
    width:300px;
}
.progress-bar-container .loader-container{
    width:100%;
    text-align:center;
    height:30px;
}


/**********************************************/
/*********** BEGIN MEDIA-QUERIES **************/
/**********************************************/

/** MOBILE **/
@media only screen and (max-width:769px){

    .header .header-search{
        width:92%;
        margin-left:8%;
    }
    .header-titles-container .header-title{
        display:none;
    }
    div.footer-content{
        justify-content:center;
    }
    div.player-content div.image-file-mobile img{
        max-height:80px;
        max-width:80px;
    }
    div.player-content div.image-file-desktop{
        display:none;
    }
    div.player-content div.image-file-mobile img{
        border-radius:4px;
        margin:4px 0 0 4px;
    }
    div.player-header div.card-info{
        font-size:12px;
    }
    div.player-header div.card_header h3{
        font-size:13px;
    }
    div.player-header div.card_header h4{
        font-size:12px;
    }

}

/** DESKTOP **/
@media only screen and (min-width:770px){

    .header-titles-container .header-title{
        line-height:1.4em;
    }
    div.footer-content{
        justify-content:flex-end;
    }
    div.player-content div.image-file-desktop{
        width:160px;
        align-self:center;
        text-align:center;
    }
    div.player-content div.image-file-desktop img{
        max-height:110px;
        max-width:100%;
        border-radius:4px;
        margin:4px 0 0 4px;
    }
    div.player-content div.image-file-mobile{
        display:none;
    }
    div.player-header div.card-info{
        font-size:14px;
    }

}